<template>
  <div class="home">
    <NavBarManager :manager="manager"/>
    <div class="container">
      <div class="container__wrapper">
        <div class="col">
          <div class="row">
            <stat-item
                class="stat__itemn"
                :stat="getTopStatistic[2]" v-if="getTopStatistic.length && getTopStatistic[0]"></stat-item>
            <stat-item
                class="stat__itemn"
                :stat="getTopStatistic[0]" v-if="getTopStatistic.length && getTopStatistic[1]"></stat-item>
            <stat-item
                class="stat__itemn"
                :stat="getTopStatistic[1]" v-if="getTopStatistic.length && getTopStatistic[2]"></stat-item>
          </div>
          <div class="row">
            <div class="col">
              <stat-item-all
                  class="stat__itemn stat__itemn_md"
                  :edus="manager.educationObjects"
                  :cameras="stat ? stat.camerasCount : 0" ></stat-item-all>
            </div>
            <stat-item
                class="stat__itemn stat__itemn_wo-title"
                :stat="getTopStatistic[4]" v-if="getTopStatistic.length && getTopStatistic[4]"></stat-item>
            <stat-item
                class="stat__itemn stat__itemn_wo-title"
                :stat="getTopStatistic[5]" v-if="getTopStatistic.length && getTopStatistic[5]"></stat-item>
            <stat-item
                class="stat__itemn stat__itemn_wo-title"
                :stat="getTopStatistic[6]" v-if="getTopStatistic.length && getTopStatistic[6]"></stat-item>
          </div>
        </div>

      </div>
      <div class="counter__wrapper" v-if="!$route.query.class">
        <div class="counter__content">
          <counter :counter="counter" v-if="counter.length"></counter>
        </div>
      </div>
      <div class="graph__wrapper">
        <graph :manager="manager"></graph>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import StatItem from "../../components/Statistic/StatItem";
import Counter from "../../components/Statistic/Counter";
import Graph from "../../components/Graph/Graph";
import StatItemAll from "../../components/Statistic/StatItemAll";
import NavBarManager from "@/components/NavBar/NavBarManager";

export default {
  name: 'ManagerHome',
  components: {NavBarManager, StatItemAll, Graph, Counter, StatItem},
  data() {
    return {
      stat: null,
      counter: [],
      all: 0,
      manager: {}
    }
  },
  computed: {
    getTopStatistic() {
      let arr = []
      if (this.stat) {
        arr.push({
          data: [
            {
              color: '#7ED321',
              text: 'Платящих',
              count: this.stat.payAccountsCount
            },
            {
              color: '#FCC400',
              text: 'Демо',
              count: this.stat.demoAccountsCount
            },
            {
              color: '#C60000',
              text: 'Неплатящих',
              count: this.stat.unPayAccountsCount
            },
          ],
          config: {
            showHeader: true,
            headerText: 'Аккаунтов',
            headerCount: this.stat.parentsCount,
            all: this.stat.parentsCount,
            headerColor: '#FF6A61',
            showGraph: true
          }
        })
        arr.push({
          data: [
            {
              color: '#FF6A61',
              text: 'Аккаунтов',
              count: this.stat.parentsCount
            },
            {
              color: '#FEDCCD',
              text: 'Незарег',
              count: (this.stat.keysCount - this.stat.parentsCount) > 0 ? (this.stat.keysCount - this.stat.parentsCount) : 0
            }
          ],
          config: {
            showHeader: true,
            headerText: 'Родителей',
            headerCount: this.stat.keysCount,
            all: this.stat.keysCount,
            headerColor: '#E8E8E8',
            showGraph: true
          }
        })
        arr.push({
          data: [
            {
              color: '#7ED321',
              text: 'Разовый',
              count: this.stat.payAccountsCount - this.stat.recurrentCount
            },
            {
              color: '#5FA019',
              text: 'Рекурент',
              count: this.stat.recurrentCount
            }
          ],
          config: {
            showHeader: true,
            headerText: 'Платящих',
            headerCount: this.stat.payAccountsCount,
            all: this.stat.payAccountsCount,
            headerColor: '#7ED321',
            showGraph: true
          }
        })
        arr.push({
          data: [
            {
              // color: '#7ED321',
              text: 'Родителей',
              count: this.stat.keysCount
            },
            {
              // color: '#5FA019',
              text: 'Ключей',
              count: this.stat.accountKeysCount
            }
          ],
          config: {
            showHeader: false,
            headerText: 'Платящих',
            headerCount: this.stat.payAccountsCount,
            all: this.stat.payAccountsCount,
            headerColor: '#8A8A8A',
            showGraph: false
          }
        })
        arr.push({
          data: [
            {
              color: '#9626C1',
              text: 'Объединенных',
              count: this.stat.joinsAccountCount
            },
            {
              color: '#FF6A61',
              text: 'Стандартных',
              count: this.stat.parentsCount - this.stat.joinsAccountCount
            },
          ],
          config: {
            showHeader: false,
            headerText: 'Аккаунтов',
            headerCount: this.stat.parentsCount,
            all: this.stat.parentsCount,
            headerColor: '#FF6A61',
            showGraph: true
          }
        })
        arr.push({
          data: [
            {
              color: '#7ED321',
              text: 'Платящих',
              count: this.stat.payAccountsCount
            },
            {
              color: '#C60000',
              text: 'Неплатящих',
              count: this.stat.parentsCount - this.stat.payAccountsCount
            },
            {
              color: '#FEDCCD',
              text: 'Незарег',
              count: (this.stat.keysCount - this.stat.parentsCount) > 0 ? (this.stat.keysCount - this.stat.parentsCount) : 0
            }
          ],
          config: {
            showHeader: false,
            headerText: 'Родителей',
            headerCount: this.stat.keysCount,
            all: this.stat.keysCount,
            headerColor: '#E8E8E8',
            showGraph: true
          }
        })

      }
      return arr
    },
    getEducation() {
      return this.$route.query.school
    },
    getClass() {
      return this.$route.query.class
    }
  },
  methods: {
    clearData() {
      this.stat = null
      this.counter = []
    },
    async loadData() {
      let resp

      resp = await this.$axios.get(`manager`)
      this.manager = resp.data

      if (this.getClass) {
        resp = await this.$axios.get(`statistic/app?school=${this.getEducation}&class=${this.getClass}`)
      }
      else if (this.getEducation) {
        resp = await this.$axios.get(`statistic/app?school=${this.getEducation}`)
      } else if (this.manager != null){
        resp = await this.$axios.get(`statistic/app?manager=${this.manager.id}`)
      }
      else {
        resp = await this.$axios.get(`statistic/app`)
      }
      if (resp) {
        if (!resp.data.keysCount) {
          resp.data.keysCount = 0
        }
        this.stat = resp.data
      }
      if (!this.getClass) {
        let classes
        if (!this.getEducation) {
          classes = await this.$axios.get('educations')
          let cl = [];
          for(let educationObject of this.manager.educationObjects){
            const obj = classes.data.filter(item => item.id === educationObject.id)[0]
            if (obj != null){
              cl.push(obj)
            }
          }

          if (cl.length > 0){
            classes.data = cl
          }
        }
        else {
          classes = await this.$axios.get('educations/' + this.$route.query.school)
        }
        this.counter = classes.data
      }
    }
  },
  mounted() {
    this.loadData()
  },
  watch: {
    getEducation() {
      this.clearData()
      this.loadData()
    },
    getClass() {
      this.clearData()
      this.loadData()
    }
  }
}
</script>
<style lang="scss" scoped>
.container {
  position: absolute;
  left: 50%;
  top: 60px;
  transform: translate(-50%);
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0 0 0px;
    max-width: 792px;
    min-height: 660px;
  }

  &__close{
    position: absolute;
    right: -85px;
    top: -40px;
    cursor: pointer;

    &:hover{
      opacity: .75;
    }
  }
}

.container__wrapper{
  margin-left: -10px;
}

.counter__wrapper {
}

.graph__wrapper {
  padding-top: 50px;
}

.counter__content {
  background: #FFFFFF;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  max-width: 792px;
  width: 100%;
  padding: 30px 15px;
}

.home {
  padding-bottom: 15vh;
}



.stat__itemn{
  width: 249px;
  height: 290px;

  &_wo-title{
    height: 250px;
  }

  &_md{
    height: 170px;
  }

  &_sm{
    height: 80px;
  }
}


.row{
  margin-top: 17px;
}
</style>

<template>
  <div class="graph" v-if="content">
    <div class="graph__head">
      <div class="graph__item" v-for="(val, i) in types" :class="activeType === val.type ? 'active' : ''" :key="i"
           @click="changeType(val.type)">
        {{ val.name }}
      </div>
    </div>
    <div class="graph__content">
      <div class="graph__header">
        <div class="graph__date">
          <div class="graph__dates" v-if="activePeriod !== 'all'">
            <svg
                @click="minusDate"
                width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="24" height="24" rx="12" fill="#F8F8F8"/>
              <path d="M14 7L9 12L14 17" stroke="#282828" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <span class="graph__active-date">{{ activeDate }}</span>
            <svg
                @click="addDate" :class="!isActualDate ? 'disabled' : ''"
                width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="24" height="24" rx="12" fill="#F8F8F8"/>
              <path d="M9 17L14 12L9 7" stroke="#282828" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </div>
        <div class="graph__periods">
          <div class="graph__period" v-for="(val, i) in periods" :key="i"
               :class="activePeriod === val.type ? 'active' : ''" @click="activePeriod = val.type">{{ val.name }}
          </div>
        </div>
      </div>
      <div class="graph__graph">
        <graph3-dv2
            :period="activePeriod"
            :graph="content.payGraph"
            :date="activeDate"
            v-if="content.payGraph"
            :head="graphHead[activeType]"
            :selected="graphHead[activeType][selectedIndexType].val"></graph3-dv2>
      </div>
      <div class="graph__types">
        <svg
            @click="prevFilter"
            width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="24" height="24" rx="12" fill="#F8F8F8"/>
          <path d="M14 7L9 12L14 17" stroke="#282828" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <p class="graph__types__type"
          :style="{'color': graphHead[activeType][selectedIndexType].textColor,
          'background': graphHead[activeType][selectedIndexType].backgroundColor}">{{graphHead[activeType][selectedIndexType].name}}</p>
        <svg
            @click="nextFilter"
            width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="24" height="24" rx="12" fill="#F8F8F8"/>
          <path d="M9 17L14 12L9 7" stroke="#282828" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
      <div class="graph__list" v-if="manager.isShowInfo">
        <table border-spacing="0">
          <thead>
          <tr>
            <th v-for="(val, i) in listHead[activeType]" :key="i">{{ val.name }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, key) in content.payData" :key="key">
            <td v-for="(val, i) in listHead[activeType]" :key="i">
              <span v-if="val.type === 'boolean'">{{ item[val.val] ? 'Да' : 'Нет' }}</span>
              <span v-else-if="val.type === 'circle'">
                <i style="width: 12px;height: 12px;border-radius: 10em;display:inline-block;" :style="{background: val.values[item[val.val]]}"></i>
              </span>
              <span v-else-if="val.type === 'time'">{{getActualDate(item[val.val])}}</span>
              <span v-else>{{ item[val.val] }}</span>
            </td>
          </tr>
          </tbody>
        </table>
        <div class="graph__pagination" v-if="getPagination.length">
          <ul>
            <li v-for="i in getPagination" :key="i" :class="page === i ? 'active' : ''" @click="page = i">
              <span >{{i}}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import _ from 'lodash'
import Graph3Dv2 from "./Graph3Dv2"

moment.locale('ru')

export default {
  name: "Graph",
  components: {Graph3Dv2},
  props:{
    manager:{
      default(){
        return {}
      }
    }
  },
  data() {
    return {
      content: {},
      selectedIndexType: 0,
      types: [
        {
          name: 'Оплаты',
          type: 'pay'
        },
        {
          name: 'Пользователи онлайн',
          type: 'online'
        },
        {
          name: 'Входы в сервис',
          type: 'input'
        },
        {
          name: 'Новые регистрации',
          type: 'reg'
        }
      ],
      periods: [
        {
          name: 'За сутки',
          type: 'day'
        },
        {
          name: 'За месяц',
          type: 'month'
        },
        {
          name: 'За весь период',
          type: 'all'
        }
      ],
      activeType: 'pay',
      activePeriod: 'day',
      activeDate: null,
      activeFormat: 'DD.MM.YYYY',
      withGraph: 1,
      page: 1,
      pageCount: 1,
      listHead: {
        pay: [
          {
            name: 'Учреждение',
            val: 'educationObject'
          },
          {
            name: 'Класс',
            val: 'class'
          },
          {
            name: 'Имя фамилия',
            val: 'fullName'
          },
          {
            name: 'Автоплатеж',
            val: 'isReccurent',
            type: 'boolean'
          },
          {
            name: 'Тариф',
            val: 'tariffPeriod'
          },
          {
            name: 'Аккаунтов',
            val: 'accountsCount'
          },
          {
            name: 'Оплата',
            val: 'isSuccess',
            type: 'boolean'
          }
        ],
        input: [
          {
            name: 'Логин',
            val: 'login'
          },
          {
            name: 'Пароль',
            val: 'password'
          },
          {
            name: 'Имя фамилия',
            val: 'fullName'
          },
          {
            name: 'Учреждение',
            val: 'educationObject'
          },
          {
            name: 'Класс',
            val: 'class'
          },
          {
            name: 'Статус',
            val: 'statusType',
            type: 'circle',
            values: ['#C60000', '#7ED321', '#FCC400']
          },
          {
            name: 'Вход',
            val: 'inputStatus'
          },
        ],
        reg: [
          {
            name: 'Учреждение',
            val: 'educationObject'
          },
          {
            name: 'Класс',
            val: 'class'
          },
          {
            name: 'Имя фамилия',
            val: 'fullName'
          },
          {
            name: 'Телефон',
            val: 'phone'
          },
          {
            name: 'Дата',
            val: 'createdAt',
            type: 'time'
          },
        ],
        online: [
          {
            name: 'Учреждение',
            val: 'educationObject'
          },
          {
            name: 'Класс',
            val: 'class'
          },
          {
            name: 'Имя фамилия',
            val: 'fullName'
          },
          {
            name: 'Телефон',
            val: 'phone'
          },
          {
            name: 'Статус',
            val: 'status',
            type: 'circle',
            values: ['#C60000', '#7ED321', '#FCC400']
          },
          {
            name: 'Кол-во устройств',
            val: 'devicesCount',
          },
        ]
      },
      graphHead: {
        reg: [
          {
            name: 'Кол-во регистраций',
            color: '#000',
            textColor: '#fff',
            backgroundColor: '#000',
            val: 'count'
          },
        ],
        pay: [
          {
            name: 'Все платежи',
            color: '#000',
            textColor: '#fff',
            backgroundColor: '#000',
            val: 'payCount'
          },
          {
            name: 'Рекурентные',
            color: '#00FCCE',
            textColor: '#fff',
            backgroundColor: '#00FCCE',
            val: 'isRecurrentCount',
          },
          {
            name: 'Разовые',
            color: '#7ED321',
            textColor: '#fff',
            backgroundColor: '#7ED321',
            val: 'successCount'
          }
        ],
        input: [
          {
            name: 'Всего',
            color: '#000',
            textColor: '#fff',
            backgroundColor: '#000',
            val: 'inputCount'
          },
          {
            name: 'Учителя',
            color: '#FCC400',
            textColor: '#fff',
            backgroundColor: '#FCC400',
            val: 'teacherCount',
          },
          {
            name: 'Неплатящие',
            color: '#C60000',
            textColor: '#fff',
            backgroundColor: '#C60000',
            val: 'unPaidCount'
          },
          {
            name: 'Платящие',
            color: '#7ED321',
            textColor: '#fff',
            backgroundColor: '#7ED321',
            val: 'paidCount'
          },
        ],
        online: [
          {
            name: 'Всего',
            color: '#000',
            textColor: '#fff',
            backgroundColor: '#000',
            val: 'count'
          },
          {
            name: 'Учителя',
            color: '#FCC400',
            textColor: '#fff',
            backgroundColor: '#FCC400',
            val: 'teacherCount',
          },
          {
            name: 'Неплатящие',
            color: '#C60000',
            textColor: '#fff',
            backgroundColor: '#C60000',
            val: 'unPaidCount'
          },
          {
            name: 'Платящие',
            color: '#7ED321',
            textColor: '#fff',
            backgroundColor: '#7ED321',
            val: 'paidCount'
          },
        ]
      }
    }
  },
  methods: {
    prevFilter(){
      if (this.selectedIndexType <= 0){
        this.selectedIndexType = this.graphHead[this.activeType].length-1
      } else {
        this.selectedIndexType--
      }
    },
    nextFilter(){
      if (this.selectedIndexType === this.graphHead[this.activeType].length -1 ){
        this.selectedIndexType = 0
      } else {
        this.selectedIndexType++
      }
    },
    getActualDate(time) {
      return moment(time, 'YYYY-MM-DD HH:mm:ss').fromNow()
    },
    minusDate() {
      this.activeDate = moment(this.activeDate, this.activeFormat).subtract(1, this.activePeriod).format(this.activeFormat)
    },
    addDate() {
      if (this.isActualDate) {
        this.activeDate = moment(this.activeDate, this.activeFormat).add(1, this.activePeriod).format(this.activeFormat)
      }
    },
    changeType(type) {
      this.activeType = type
      this.selectedIndexType = 0
    },
    clearData() {
      this.content = {}
      this.withGraph = 1
      this.pageCount = 1
      this.page = 1
    },
    async loadData() {
      let periodToNumber
      switch (this.activePeriod) {
        case 'day': {
          periodToNumber = 0
          break
        }
        case 'month': {
          periodToNumber = 1
          break
        }
        case 'all': {
          periodToNumber = 2
          break
        }
        default: {
          periodToNumber = 0
        }
      }
      let date = this.activeDate
      if (this.activePeriod === 'month') {
        date = moment(this.activeDate, this.activeFormat).format('DD.MM.YYYY')
      }
      if (this.activePeriod === 'all') {
        date = moment(this.activeDate, this.activeFormat).format('DD.MM.YYYY')
      }
      let resp
      if (this.$route.query.school) {
        resp = await this.$axios.get(`history/${this.activeType}/${this.$route.query.school}?period=${periodToNumber}&date=${date}&withGraph=${this.withGraph}&page=${this.page}`)
      }
      else {
        resp = await this.$axios.get(`history/${this.activeType}?period=${periodToNumber}&date=${date}&withGraph=${this.withGraph}&page=${this.page}`)
      }
      if (this.withGraph) {
        this.content = resp.data
      }
      else {
        this.content.payData = resp.data.payData
      }
      if (this.withGraph) {
        this.pageCount = this.content.payCounts ?  Math.ceil(this.content.payCounts / 10) : 1
      }
      this.withGraph = 0
    }
  },
  computed: {
    getPagination() {
      if (this.pageCount > 1) {
        return _.range(Number(this.page) - 5, Number(this.page) + 5).filter(item => item > 0 && item <= this.pageCount)
      }
      return []
    },
    isActualDate() {
      console.log(this.activeDate)
      if (this.activeDate) {
        const date = moment(this.activeDate, this.activeFormat).add(1, this.activePeriod)
        const res = moment().isAfter(date)
        console.log(res)
        return res
      } else {
        return false
      }
    },
    getSchool() {
      return this.$route.query.school
    },
    getClass() {
      return this.$route.query.class
    }
  },
  watch: {
    async getSchool() {
      this.clearData()
      await this.loadData()
    },
    async getClass() {
      this.clearData()
      await this.loadData()
    },
    async activeType() {
      this.clearData()
      await this.loadData()
    },
    async page() {
      await this.loadData()
    },
    async activeDate() {
      this.clearData()
      await this.loadData()
    },
    async activePeriod() {
      if (this.activePeriod === 'day') {
        this.activeFormat = 'DD.MM.YYYY'
      } else {
        this.activeFormat = 'MMMM YYYY'
      }
      this.activeDate = moment().format(this.activeFormat)
      this.clearData()
      await this.loadData()
    }
  },
  async mounted() {
    this.activeDate = moment().format(this.activeFormat)
    await this.loadData()
  }
}
</script>

<style scoped lang="scss">
.graph {
  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
    height: 48px;
    max-width: 792px;
    border-radius: 8px;
  }

  &__item {
    width: 25%;
    text-align: center;
    cursor: pointer;
    opacity: .5;
    color: #3B3F48;
    font-weight: 500;
    font-size: 14px;
    line-height: 44px;
    &.active {
      opacity: 1;
      color: #6099FC;
      border-bottom: 3px solid #6099FC;
      border-bottom-left-radius: 2px;
      border-bottom-right-radius: 2px;

    }
  }

  &__content {
    margin-top: 15px;
    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
    max-width: 792px;
    border-radius: 8px;
  }

  &__header {
    padding: 10px 15px;
    padding-top: 30px;
    padding-bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__dates {
    display: flex;
    align-items: center;
    user-select: none;
    margin-left: 39px;

    span {
      user-select: none;
      width: 240px;
      text-align: center;
    }

    svg {
      cursor: pointer;
      vertical-align: middle;

      rect{
        opacity: 0;
      }


      &.disabled {
        opacity: 0;
        pointer-events: none;
      }

      &:hover{
        rect{
          opacity: 1;
        }
      }
    }
  }

  &__date {
    width: 40%;

  }

  &__periods {
    width: 387px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 25px;
  }

  &__period {
    background: transparent;
    border: 1px solid #fafafa;
    color: black;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 6px;
    padding-left: 23px;
    padding-right: 23px;
    text-align: center;
    cursor: pointer;
    border-radius: 30px;
    white-space: nowrap;
    margin-left: 12px;
    margin-right: 12px;

    &:first-child{
      margin-left: 0;
      margin-right: 0;

    }

    &:last-child{
      margin-left: 0;
      margin-right: 0;
    }


    &.active {
      background: #6099FC;
      color: white;
    }
  }

  &__types{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    svg{
      cursor: pointer;

      rect{
        opacity: 0;
      }

      &:hover{
        rect{
          opacity: 1;
        }
      }
    }

    &__type{

      width: 120px;
      border-radius: 30px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      padding-top: 8px;
      padding-bottom: 8px;
      text-align: center;
      /* identical to box height */

      letter-spacing: -0.0814546px;
      margin-left: 80px;
      margin-right: 80px;
    }
  }

  &__graph {
    min-height: 450px;
  }
  &__pagination {
    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      list-style-type: none;
    }
    li {
      margin: 0 3px;
      cursor: pointer;
      padding: 4px 8px;

      /* 1 */

      width: 27px;
      height: 30px;

      font-family: 'Roboto';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 21px;
      /* identical to box height */
      text-align: center;

      color: #6099FC;
      border-radius: 3px;



      &.active {
        background: #6099FC;
        color: #fff;
      }
    }
  }
  &__list {
    padding: 50px 25px;

    table {
      width: 100%;
      border-spacing: 0;

      th, td {
        padding: 13px 10px;
      }

      thead {
        th {
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 16px;
          color: #3B3F48;
          text-align: left;
        }
      }

      tbody {
        td {
          font-weight: normal;
          font-size: 12px;
        }
      }
    }
  }
}

.graph__active-date {
  font-size: 14px;

  &:first-letter {
    text-transform: uppercase;
  }
}
</style>